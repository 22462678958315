import React, { useEffect, useState } from "react";
import "./footer.scss";
import * as _ from "lodash";
import SocialMedia from "../../components/social-media/social-media";

const Footer = ({ profile }) => {
  const [addressParts, setAddressParts] = useState([]);

  const [operatingHourGroups, setOperatingHourGroups] = useState([]);

  useEffect(() => {
    if (profile && profile.address) {
      setAddressParts(profile.address.split(" "));
    }

    if (profile.operationalHours) {
      resolveOperatingHoursGroups(profile.operationalHours);
    }
    return () => {};
  }, [profile]);

  const resolveOperatingHoursGroups = (operationalHours) => {
    let days = Object.keys(operationalHours);
    let dayGroups = [];
    let daysOfWeek = [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday",
    ];

    while (days.length) {
      const day = days[0];
      const thisGroup = days.filter(
        (d) =>
          operationalHours[d].startHour === operationalHours[day].startHour &&
          operationalHours[d].endHour === operationalHours[day].endHour
      );
      thisGroup.sort((a, b) =>
        daysOfWeek.indexOf(a.toLowerCase()) <
        daysOfWeek.indexOf(b.toLowerCase())
          ? -1
          : 1
      );

      dayGroups.push({
        words: thisGroup.reduce((previous, current) => {
          return (
            previous +
            (previous.length ? ", " : "") +
            _.capitalize(current.substr(0, 3))
          );
        }, ""),
        time:
          operationalHours[day].startHour +
          " - " +
          operationalHours[day].endHour,
      });

      _.remove(days, (n) => {
        return thisGroup.includes(n);
      });
    }

    setOperatingHourGroups(dayGroups);
  };

  return (
    <section className="footerSection" id="footerSection">
      <div className="topSection">
        <img className="logo" src="/images/logo.webp"></img>
        <div className="operationalHoursSection">
          <span
            className="header"
            style={{
              gridColumn: "span 2",
              textAlign: "left",
              fontSize: "24px",
            }}
          >
            Operating Hours
          </span>

          <p className="body h6">Weekdays : 06:30 - 14:00</p>
          <p className="body h6">Weekends : 07:00 - 13:00</p>
        </div>
        <div className="contactSection">
          <div>
            <img src="/images/phone.png"></img>
            <span className="body h6">
              <a
                href={`tel:${
                  profile && profile.phonenumber
                    ? profile.phonenumber
                    : "+61731956381"
                }`}
              >{`${
                profile && profile.phonenumber
                  ? profile.phonenumber
                  : "07-31-956381"
              }`}</a>
            </span>
          </div>
          <div>
            {" "}
            <img src="/images/email.png"></img>
            <span className="body h6">
              <a
                href={`mailto:${
                  profile && profile.email
                    ? profile.email
                    : "littlehideoutcafe185@gmail.com"
                }`}
              >{`${
                profile && profile.email
                  ? profile.email
                  : "littlehideoutcafe185@gmail.com"
              }`}</a>
            </span>
          </div>
          <div>
            {" "}
            <img src="/images/marker.png"></img>
            <span className="body h6">
              <a
                target="_blank"
                href="https://www.google.com/maps/search/?api=1&query=2%2F185+Riding+Rd,+Balmoral+QLD+4171"
              >
                {profile && profile.address
                  ? profile.address
                  : "2/185 Riding Rd, Balmoral QLD 4171"}
              </a>
            </span>
          </div>
        </div>

        <div className="mobileSocials">
          <SocialMedia orientation="horizontal" />
        </div>
        {/* <div className="formSection">
                <span className="header formHeader" style={{textAlign: 'left', fontSize: '24px' }}>Get in touch</span>
                <input className="contactUsInput" type="text" placeholder="Name" />
                <input className="contactUsInput" type="email" placeholder="Email" />
                <textarea className="contactUsInput" type="text" placeholder="Message" />
                <button className="customButton contactUsSubmitButton" style={{ padding: 0 }}>
                    Submit
                </button>
            </div> */}
      </div>
      <div className="bottomSection">
        <div className="leftSection bodyParagraph">
          ©{new Date().getFullYear()} Little Hideout Cafe All Rights Reserved
        </div>
        <div className="rightSection bodyParagraph">
          Designed & developed by{" "}
          <a href="https://portfolio-kripson.web.app"> Kripson</a>
        </div>
      </div>
    </section>
  );
};

export default Footer;
