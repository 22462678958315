import React from "react";
import './social-media.scss';


const SocialMedia = ({orientation}) => {

    return <div className={`socialMedia ${orientation}`}>
        <a href="https://www.facebook.com/Littlehideoutcafe"  target="_blank" rel="noreferrer"><img src="/images/facebook.svg" alt="facebook"></img></a>
        <a href="https://www.instagram.com/littlehideoutcafe/"  target="_blank" rel="noreferrer"><img src="/images/instagram.svg" alt="instagram"></img></a>
        {/* <a href=""  target="_blank" rel="noreferrer"><img src="/images/twitter.svg" alt="twitter"></img></a> */}

    </div>
}

export default SocialMedia;