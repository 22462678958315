import React, { useEffect } from "react";
import "./book-a-table.scss";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Card,
  TextField,
  InputAdornment,
  Chip,
  Hidden,
} from "@mui/material";
import StaticDatePicker from "@mui/lab/StaticDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AccountCircle from "@mui/icons-material/AccountCircle";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import MobileDatePicker from "@mui/lab/MobileDatePicker";

const BookATable = ({ setShowBookingSection }) => {
  const steps = [
    "Select no of people",
    "Select Date",
    "Select Time",
    "Details",
  ];
  const [activeStep, setActiveStep] = React.useState(0);
  const [newBooking, setNewBooking] = React.useState({});
  const [availableTimeSlots, setAvailableTimeSlots] = React.useState([]);
  const [retrievingSlots, setRetrievingSlots] = React.useState(false);
  const [bookingComplete, setBookingComplete] = React.useState(false);

  const handleNext = () => {
    if (checkStepCompletion()) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = async () => {
    try {
      const response = await axios({
        url: "https://littlehideoutserver-k2cd4.ondigitalocean.app/bookings/createBooking",
        method: "POST",
        data: {
          newBooking: {
            ...newBooking,
            noOfPeople: Number(newBooking.noOfPeople),
          },
        },
      });

      if (response.data.status === 1) {
        setBookingComplete(true);
      }
    } catch (e) {
      alert("Something went wrong please try again.");
    }
  };

  useEffect(() => {
    if (activeStep === 2) {
      getAvailableTimeSlots();
    }
  }, [activeStep]);

  const checkStepCompletion = () => {
    if (activeStep === 0) {
      if (!newBooking.noOfPeople) {
        return false;
      }
    } else if (activeStep === 1) {
      if (!newBooking.date) {
        return false;
      }
    } else if (activeStep === 2) {
      if (!newBooking.time) {
        return false;
      }
    } else if (activeStep === 3) {
      if (!(newBooking.name && newBooking.email && newBooking.phonenumber)) {
        return false;
      }
    }

    return true;
  };

  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
            label={"No of people"}
            variant="outlined"
            onChange={(e) =>
              setNewBooking({ ...newBooking, noOfPeople: e.target.value })
            }
            value={newBooking.noOfPeople ? newBooking.noOfPeople : ""}
          />
        );
      case 1:
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Hidden smDown>
              <StaticDatePicker
                sx={{ backgroundColor: "black" }}
                className="staticDatePicker"
                disablePast
                label="Booking Date"
                openTo="day"
                views={["year", "month", "day"]}
                value={newBooking && newBooking.date ? newBooking.date : ""}
                onChange={(value) =>
                  setNewBooking({
                    ...newBooking,
                    date: value,
                  })
                }
                renderInput={(params) => <TextField {...params} />}
              />
            </Hidden>
            <Hidden smUp>
              <MobileDatePicker
                sx={{ backgroundColor: "black" }}
                className="mobileDatePicker"
                disablePast
                label="Booking Date"
                openTo="day"
                views={["year", "month", "day"]}
                value={newBooking && newBooking.date ? newBooking.date : ""}
                onChange={(value) =>
                  setNewBooking({
                    ...newBooking,
                    date: value,
                  })
                }
                renderInput={(params) => <TextField {...params} />}
              />
            </Hidden>
          </LocalizationProvider>
        );
      case 2:
        return retrievingSlots ? (
          <Box
            sx={{
              width: "100%",
              display: "grid",
              height: "100%",
              justifyItems: "center",
            }}
          >
            <lottie-player
              src="/lottie-files/loading-animation.json"
              background="transparent"
              speed="1"
              style={{ width: "200px", height: "200px" }}
              loop
              autoplay
            ></lottie-player>
            <Typography variant={"subtitle1"}>
              Retrieving available time slots.
            </Typography>
          </Box>
        ) : (
          <Box
            fullWidth
            sx={{
              maxWidth: "500px",
              display: "flex",
              flexWrap: "wrap",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {availableTimeSlots.length ? (
              availableTimeSlots.map((slot) => (
                <Chip
                  sx={{ marginRight: "1rem" }}
                  className={slot === newBooking.time ? "selectedSlot" : ""}
                  icon={<QueryBuilderIcon />}
                  label={slot}
                  variant="outlined"
                  onClick={(e) =>
                    setNewBooking({
                      ...newBooking,
                      time: slot,
                    })
                  }
                />
              ))
            ) : (
              <Typography variant={"subtitle1"}>
                Sorry, no available time slots for this day.
              </Typography>
            )}
          </Box>
        );
      case 3:
        return (
          <Box
            fullWidth
            sx={{
              maxWidth: "600px",
              display: "grid",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <TextField
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
              label={"Name"}
              variant="outlined"
              onChange={(e) =>
                setNewBooking({ ...newBooking, name: e.target.value })
              }
              value={newBooking.name ? newBooking.name : ""}
            />
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AlternateEmailIcon />
                  </InputAdornment>
                ),
              }}
              margin="normal"
              label={"Email"}
              variant="outlined"
              onChange={(e) =>
                setNewBooking({ ...newBooking, email: e.target.value })
              }
              value={newBooking.email ? newBooking.email : ""}
            />
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneIphoneIcon />
                  </InputAdornment>
                ),
              }}
              margin="normal"
              label={"Phone Number"}
              variant="outlined"
              onChange={(e) =>
                setNewBooking({ ...newBooking, phonenumber: e.target.value })
              }
              value={newBooking.phonenumber ? newBooking.phonenumber : ""}
            />
          </Box>
        );
      default:
        return;
    }
  };

  const getAvailableTimeSlots = async () => {
    try {
      setRetrievingSlots(true);
      const availableTimeSlotsResponse = await axios({
        url: "https://littlehideoutserver-k2cd4.ondigitalocean.app/bookings/getAvailableTimeSlots",
        method: "POST",
        data: {
          selectedDate: newBooking && newBooking.date ? newBooking.date : "",
          noOfPeople: newBooking.noOfPeople ? Number(newBooking.noOfPeople) : 0,
        },
      });

      if (availableTimeSlotsResponse.data.status === 1) {
        setAvailableTimeSlots(availableTimeSlotsResponse.data.result);
      }
    } catch (e) {
      alert("Something went wrong, please try again later");
    }

    setTimeout(() => {
      setRetrievingSlots(false);
    }, 1000);
  };

  return (
    <Box className={"bookATable"}>
      <Card
        sx={{
          padding: "1rem",
          minWidth: "300px",
          minHeight: "50vh",
        }}
      >
        {bookingComplete ? (
          <Box
            sx={{
              width: "100%",
              display: "grid",
              height: "100%",
              justifyItems: "center",
            }}
          >
            <Button
              onClick={() => setShowBookingSection(false)}
              variant="contained"
              sx={{
                backgroundColor: "black",
                width: "100%",
                marginBottom: "1rem",
                height: "100px",
              }}
              startIcon={<CloseIcon sx={{ margin: 0 }} />}
            >
              Close
            </Button>

            <lottie-player
              src="https://assets9.lottiefiles.com/packages/lf20_oUXj84.json"
              background="transparent"
              speed="1"
              style={{ width: "200px", height: "200px" }}
              autoplay
            ></lottie-player>
            <Typography variant={"subtitle1"}>
              Your booking has been confirmed. You will receive a confirmation
              email shortly.
            </Typography>
          </Box>
        ) : (
          <Box sx={{ width: "100%", display: "grid", height: "100%" }}>
            <Button
              onClick={() => setShowBookingSection(false)}
              variant="contained"
              sx={{
                backgroundColor: "black",
                marginBottom: "1rem",
                height: "100px",
              }}
              startIcon={<CloseIcon sx={{ margin: 0 }} />}
            >
              Close
            </Button>
            <Hidden mdDown>
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};

                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </Hidden>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  All steps completed - you&apos;re finished
                </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}></Typography>
                <Typography
                  variant={"subtitle1"}
                  sx={{ display: { xs: "block", lg: "none" } }}
                >
                  {activeStep + 1 + ". " + steps[activeStep]}
                </Typography>
                {getStepContent()}

                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: "1 1 auto" }} />

                  <Button
                    disabled={!checkStepCompletion()}
                    onClick={
                      activeStep === steps.length - 1
                        ? handleSubmit
                        : handleNext
                    }
                  >
                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                  </Button>
                </Box>
              </React.Fragment>
            )}
          </Box>
        )}
      </Card>
    </Box>
  );
};

export default BookATable;
