import LazyLoad from "react-lazyload";
import "./App.scss";
import TopBar from "./components/topbar/topbar";
import About from "./sections/about/about";
import Footer from "./sections/footer/footer";
import Gallery from "./sections/gallery/gallery";
import HeroSection from "./sections/hero/hero.jsx";
import axios from "axios";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";

function App() {
  const [profile, setProfile] = useState({});
  const [noticeShown, setNoticeShown] = useState(false);


  useEffect(() => {
    getProfileInfo();
    return () => {};
  }, []);

  const getProfileInfo = async () => {
    try {
      const profileInfo = await axios({
        url: "https://littlehideoutserver-k2cd4.ondigitalocean.app/admin/getProfileInfo",
        method: "POST",
        data: {},
      });
      setProfile(profileInfo.data.result);
    } catch (e) {}
  };

  useEffect(() => {
    if (profile._id) {
      if (profile.cafeNotice && !noticeShown) {
        setTimeout(() => {
          Swal.fire({
            title: "Hello",
            imageUrl: "/images/award.webp",
            html: `<span>
            ${profile.cafeNotice}
            </span>`,
            imageHeight: "350",
            confirmButtonText: "Close",
            confirmButtonColor: "#000",
          });

          setNoticeShown(true);
        }, 5000);
      }
    }

    return () => {};
  }, [profile]);

  const downloadMenu = () => {
    let newWindow = window.open();
    const blob = b64toBlob(profile.menu.split(",")[1], "application/pdf");
    const blobUrl = URL.createObjectURL(blob);

    newWindow.location = blobUrl;

    newWindow.onload = () => {
      newWindow.document.title = "menu";
    };
  };

  const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  return (
    <ParallaxProvider>
      <div className="App">
        {/* App is made up of different sections */}

     
          <div className="moneyPlant">
            <img src="/images/money_plant_vine.png" alt="money_plant"></img>
          </div>
     
        <TopBar profile={profile} downloadMenu={downloadMenu} />
        <HeroSection downloadMenu={downloadMenu} />

        <div id="aboutSection">
          <LazyLoad>
            <About />
          </LazyLoad>
        </div>

        <div id="gallerySection">
          <LazyLoad>
            <Gallery />
          </LazyLoad>
        </div>

        <div id="footerSection">
          <LazyLoad>
            <Footer profile={profile} />
          </LazyLoad>
        </div>
      </div>
    </ParallaxProvider>
  );
}

export default App;
