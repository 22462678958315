import React, { useEffect, useRef, useState } from "react";
import BookATable from "../book-a-table/book-a-table";
import Menu from "../menu/menu";
import "./topbar.scss";

const TopBar = ({ profile, downloadMenu }) => {
  const player = useRef(null);
  const [menuOpen, setMenuOpen] = useState(null);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    if (player) {
      player.current.addEventListener("complete", (evt) => {
        player.current.stop();
      });
    }
  }, [player]);

  const onMenuClick = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    if (menuOpen !== null) {
      clearTimeout(timer);
      player.current.setDirection(menuOpen ? 1 : -1);
      player.current.play();
    }

    if (menuOpen) {
      setTimer(
        setTimeout(() => {
          player.current.pause();
        }, 700)
      );
    }
  }, [menuOpen]);

  return (
    <div className="topBar">
      <section className="leftSection topBarSection">
        <span className="header" style={{ width: "min-content" }}>
          <img src="images/phone.png" /> <a href={`tel:+61401646685`}>+61401646685</a>
        </span>
      </section>
      <Menu open={menuOpen} downloadMenu={downloadMenu} setMenuOpen={setMenuOpen} />
      <section className="middleSection topBarSection">
        <span>
          <img className="logo" src="/images/logo.webp" alt="logo"></img>
        </span>
      </section>
      <section className="topBarSection rightSection">
        <span className="burgerMenuButton">
          <lottie-player ref={player} onClick={onMenuClick} src="https://assets8.lottiefiles.com/packages/lf20_cwjG6q.json" background="transparent" speed="2" style={{ width: "64px", height: "64px" }}></lottie-player>
        </span>
      </section>
    </div>
  );
};

export default TopBar;
