import React from "react";
import LazyLoad from "react-lazyload";
import "./about.scss";

const About = () => {
  return (
    <div id="aboutSection">
      <LazyLoad>
        <section className="aboutSection">
          <div className="leftSection">
            <div className="backgroundBox">
              <img src="/images/food.webp" className=" food"></img>

              <img src="/images/coffee.webp" className="coffee"></img>
            </div>
          </div>

          <div className="rightSection">
            <h1 className="header sectionHeader">About Us</h1>
            <div className="headerUnderline"></div>
            <p className="bodyParagraph">
              Located in the heart of Brisbane's Balmoral, Little hideout is a hidden gem that offers a lot, including our back deck seating area. A wonderful hideout for you and your friends. Find us today and experience the serenity.
            </p>
            <p className="bodyParagraph">Enjoy a balanced menu of healthy, fun and indulgent foods.</p>
            <p className="bodyParagraph">
              <h5 style={{ marginBottom: 0 }}>
                Bookings
              </h5>
              5 people or more - 1hr <br />
              under 5 people - walk in <br />
            </p>

            <h5 className="header textSecondary"> What we offer at Little hideout</h5>

            <div className="featureSection">
              <div className="feature">
                <img src="/images/dish.png"></img>
                <p className="bodyParagraph">Breakfast and lunch with a selection of locally sourced bites. We support local bread, baked fresh from wild breads. Fruits and veggies from Big micheal.</p>
              </div>

              <div className="feature">
                <img src="/images/coffee-cup.png"></img>
                <p className="bodyParagraph">Day or Night, Sunny or Grey. Enjoy our coffee by Tim Adams Coffee specialty.</p>
              </div>

              <div className="feature">
                <img src="/images/paw.png"></img>
                <p className="bodyParagraph">Boasting one of Balmoral's hidden gems our little cafe offers a lot more including our back deck seating area and pet friendly.</p>
              </div>
            </div>
          </div>
        </section>
      </LazyLoad>
    </div>
  );
};

export default About;
