import React from "react";
import "./menu.scss";

const Menu = ({ open, downloadMenu, setMenuOpen }) => {
  return (
    <div
      className={`menu ${open ? "menuOpen" : open !== null ? "menuClose" : ""}`}
    >
      <ul>
        <li className="menuItem header h3" onClick={()=>setMenuOpen(false)}>
          <a href="#heroSection"> Home </a>
        </li>

        <li className="menuItem  header h3" onClick={()=>{
          downloadMenu();
          setMenuOpen(false);
        }}>
          Menu
        </li>
        <li className="menuItem  header h3" onClick={()=>setMenuOpen(false)}>
          <a href="#aboutSection"> About </a>
        </li>
        <li className="menuItem  header h3" onClick={()=>setMenuOpen(false)}>
          <a href="#gallerySection"> Gallery </a>
        </li>
        <li className="menuItem  header h3" onClick={()=>setMenuOpen(false)}>
          <a href="#footerSection"> Contact Us </a>
        </li>
      </ul>
    </div>
  );
};

export default Menu;
