import React from "react";
import "./gallery.scss";

const Gallery = () => {
  return (
    <div className="gallerySection">
      
      <a className="instagram-link" href="https://www.instagram.com/littlehideoutcafe/" target="_blank" rel="Noreferrer noopener">
        <img src="/images/hand-arrow-down.png" alt="arrow"></img>
        <h6 className="h6">Click here to see more @littlehideoutcafe</h6>
      </a>

      <div className="innerContainer">
        <div className="leftSection">
          <div
            style={{
              backgroundImage: "url(/images/gallery1.webp)",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center center",
              animationName: "gallery1",
            }}
            className="galleryImage gallery1 doubleSpanImage"
          ></div>
          <div className="bottomRow">
            <div
              style={{
                backgroundImage: "url(/images/gallery2.webp)",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                animationName: "gallery2",
              }}
              className="galleryImage  gallery2 singleSpanImage"
            ></div>
            <div
              style={{
                backgroundImage: "url(/images/gallery3.webp)",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                animationName: "gallery3",
              }}
              className="galleryImage  gallery3 singleSpanImage"
            ></div>
          </div>
        </div>

        <div className="rightSection">
          <div
            className="galleryImage gallery4"
            style={{
              backgroundImage: "url(/images/gallery4.webp)",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              animationName: "gallery4",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
