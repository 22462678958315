import React, { useState } from "react";
import Swal from "sweetalert2";
import BookATable from "../../components/book-a-table/book-a-table";
import NavigationTicks from "../../components/navigation-ticks/navigation-ticks";
import SocialMedia from "../../components/social-media/social-media";
import TopBar from "../../components/topbar/topbar";
import "./hero.scss";

const HeroSection = ({ downloadMenu }) => {
  const [showBookingSection, setShowBookingSection] = useState(false);


  function showWinnerPopUp() {

    console.log('test');
    Swal.fire({
        title: "Winner of Australia's Best Avo Toast 2022 🎉",
        imageUrl: "/images/award.webp",
        html: `<span>Start your day with our award winning avo toast. <br/><br/>
        </span>`,
        imageHeight: "350",
        confirmButtonText: "Close",
        confirmButtonColor: "#000",
      });
  }

  return (
    <div className="heroSection appSection" id="heroSection">
      {/* <NavigationTicks/> */}
      <section className="heroSectionMiddle">
        <h1 className="header">
          Little Hideout
          <div className="winner-status">
            <img src="/images/hand-arrow-down.png" alt="arrow" onClick={()=>showWinnerPopUp()}></img>
            <span className="h6" onClick={()=>showWinnerPopUp()}>Home of Australia’s official best Avo toast <img src="/images/avocado-fruit-icon.svg"></img> </span>
          </div>
        </h1>

        <span className="bodyParagraph heroTagLine">The best place to kick off your day or just take a break and enjoy a yummy breakfast.</span>
      </section>
      <section className="heroButtonSection">
        <button className="customButton menuButton" onClick={downloadMenu}>
          Menu
        </button>

        <button className="customButton bookATableButton" onClick={() => setShowBookingSection(true)}>
          Book a table
        </button>
      </section>
      <section className="heroSectionBottom">
        <div className="bottomSection"></div>
        <div className="bottomSection downArrowSection">
          <img src="/images/down-arrow.png" alt="down arrow" className="downArrow"></img>
          <p>Scroll Down</p>
        </div>
        <div className="bottomSection socialMediaContainer">
          <SocialMedia orientation="vertical" />
        </div>
      </section>

      {showBookingSection ? <BookATable setShowBookingSection={setShowBookingSection} /> : ""}
    </div>
  );
};

export default HeroSection;
